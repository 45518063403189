body {
  margin: 0 !important;
  padding: 0;
  min-width: fit-content;
  height: 100%;
  font-family: "Roboto",Helvetica Neue,Helvetica,Arial,sans-serif;
    background-color: #eeeded;
   
}

html{
  margin: 0 !important;
  padding: 0;
  height: 100%;
  width: 100%;
}

#root{
 height: 100%;
 width: 100%;
 min-width: fit-content;
}
/* Effects */
.effect6
{
    position:relative;
    -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
       -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.effect6:before, .effect6:after
{
  content:"";
    position:absolute;
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
    box-shadow:0 0 20px rgba(0,0,0,0.8);
    top:50%;
    bottom:0;
    left:10px;
    right:10px;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
}
.effect6:after
{
  right:10px;
    left:auto;
    -webkit-transform:skew(8deg) rotate(3deg);
       -moz-transform:skew(8deg) rotate(3deg);
        -ms-transform:skew(8deg) rotate(3deg);
         -o-transform:skew(8deg) rotate(3deg);
            transform:skew(8deg) rotate(3deg);
}

.effect8
{
    position:relative;
    -webkit-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
       -moz-box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
            box-shadow:0 1px 4px rgba(0, 0, 0, 0.3), 0 0 40px rgba(0, 0, 0, 0.1) inset;
}
.effect8:before, .effect8:after
{
  content:"";
    position:absolute;
    z-index:-1;
    -webkit-box-shadow:0 0 20px rgba(0,0,0,0.8);
    -moz-box-shadow:0 0 20px rgba(0,0,0,0.8);
    box-shadow:0 0 20px rgba(0,0,0,0.8);
    top:10px;
    bottom:10px;
    left:0;
    right:0;
    -moz-border-radius:100px / 10px;
    border-radius:100px / 10px;
}
.effect8:after
{
  right:10px;
    left:auto;
    -webkit-transform:skew(8deg) rotate(3deg);
       -moz-transform:skew(8deg) rotate(3deg);
        -ms-transform:skew(8deg) rotate(3deg);
         -o-transform:skew(8deg) rotate(3deg);
            transform:skew(8deg) rotate(3deg);
}
/* Navbar */

.dashboard-nav-button{
  margin-left: 20px;
  width: 240px;
  height: 25px;
}
.dashboard-nav-button-text {
  cursor: pointer;
  display: inline;
  margin-left: 15px;
  font-weight: 500;
  font-size: 14px;
  color: rgb(214, 214, 214);
}

.play-nav-button{
  margin-left: 20px;
  width: 240px;
  height: 25px;
} 

#icon-projects {
  color: rgb(214, 214, 214);
}
#icon-home {
  color: rgb(214, 214, 214);
}

.play-nav-button-text {
  cursor: pointer;
  display: inline;
  margin-left: 15px;
  font-weight: 500;
  font-size: 14px;
  color: rgb(214, 214, 214);
}

.sip-opener {
  background-color: #263238;
  border: none;
}
.sip-text {
  display: inline;
  font-size: 12px;
  margin-left: 5px;
}

.btn-primary{
  margin-top: 20px;
  display: block;
  height: 50px;
  width: 200px;

  /*TYPE*/
  color: white;
  font: 17px/50px Helvetica, Verdana, sans-serif;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;

  /*GRADIENT*/  
  background: #00b7ea; /* Old browsers */
  background: -moz-linear-gradient(top, #00b7ea 0%, #009ec3 100%); /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#00b7ea), color-stop(100%,#009ec3)); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, #00b7ea 0%,#009ec3 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, #00b7ea 0%,#009ec3 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, #00b7ea 0%,#009ec3 100%); /* IE10+ */
  background: linear-gradient(top, #00b7ea 0%,#009ec3 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00b7ea', endColorstr='#009ec3',GradientType=0 ); /* IE6-9 */

}

.btn-primary:active:focus {
  color: #ffffff; 
  background-color: #161617; 
  border-color: #494F57;
}

.user-icon {
  border-radius: 50%;
  width: 36px;
  height: 36px;
}
.phone-icon {
  width: 12px;
  color: rgb(155, 153, 153);
  margin-bottom: -1px;
}
/* Modal */
.modal-title {
  margin-bottom: 30px;
  font-weight:400;
  line-height: 1.5384616;
}
.modal-sip-text {
  margin-left: 3px;
}
.modal-close {
  margin-left: 340px;
  cursor: pointer;
}

/* Container projects */

.container-projects {
  margin-top: 50px;
  position: relative;
  margin-left: 30px;
  font-size: 19px;
  color: black;
  max-height: 700px;
}
.projects-text {
  font-weight: 500;
  display: inline;
}

.content-projects {
 margin-top: 40px;
}
.chain-projects-text {
  margin-right: 10px;
}

.ch-row {
  width: 400px;
}
.panel {
  width: 400px;
  height: 80px;
  background-color: rgb(255, 255, 255);
}
.media {
  height: 60px;
  cursor: pointer;
}
.media-body {
  margin-top: 10px;
}
.media-heading {
  margin-top: 5px;
  margin-bottom: -5px;
}
.text-muted {
  font-size: 15px;
}
.text-success-800 {
  color: green;

}
.text-danger-800 {
  color: red;

}
.offline-play {
  margin-left: 20px;
  margin-top: 18px;
  font-size: 40px;
  color: rgb(51, 49, 49);
  cursor: pointer;
}
.offline-play:hover {
  color:#576368;
  transition-duration: 0.5s;
}

@supports not (-webkit-touch-callout: none) {
 
}
@media (max-width: 767px) { 
 .collapsing {
  -webkit-transition-delay: none;
  transition-delay: none;
  transition: none;
}
 }
/* Form page */

.row {
  margin: 10px 10px 10px 10px;
  padding: 20px 10px 10px 10px;
  height: fit-content;
}

.select-drop {
  border-radius: 2px;
  margin-bottom: 10px;
  margin-left: 20px;
  width: 90%;
  height: 40px;
  border-color: rgb(216, 216, 216);
  color: rgb(78, 78, 78);
}

.no {
  pointer-events: none;
  background-color: black;
}

#county-select {
  margin-top: 30px;
}
select {
  border-radius: 2px;
  margin-bottom: 10px;
  width: 90%;
  height: 40px;
  border-color: rgb(216, 216, 216);
  color: rgb(78, 78, 78);
}

#collapse6 {
  border-right: black;
}

.select-label {
  margin-top: 20px;
  margin-left: 50px;
  width: 10%;
  margin-bottom: 8px;
  font-weight: 400;
  font-size: 13px;
}

.row-form {
  background-color: white;
  width: auto;
  max-width: 100%;
}

h3 {
  text-transform: uppercase;
}

.change-theme {
  color: white;
}

.status-button {
  color: white;
  font-weight: 300;
  margin-left: -10%;
}

textarea {
  width: 100%;
  height: 60px;
  line-height: 20px;
  top: -12px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  font-size: 24px;
  
  position: relative;
  padding: 0 2px;
}
textarea:focus{
  outline: none;
  border-color: green;
}

input[type="number"] {
  width: 100%;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  margin-bottom: 20px;
}


input[type="radio"] {
  margin-left: 5px;
}
input[type="number"]:focus {
  outline: none;
  border-color: green;
}

.reset-button {
  background-color: #37474F;
  border-color: #37474F;
  color: #fff;
  margin-right: 400px;
  border: none;
  font-weight: 500;
  border-radius: 3px;
  width: 150px;
  height: 40px;
  margin-bottom: 20px;
}

input[type="text"] {
  max-width: 150%;
  width: 100%;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  margin-bottom: 20px;
  height: 45px;
   font-size: 22px;
}
.dontknow {
  font-size: 19px;
}
input[type="text"]:focus {
  outline: none;
  border-color: green;
}
label {
  width: 87%;
}
.question-number {
  margin: 0!important;
  font-weight: 700;
  font-size: 21px;
}

.question-text {
  margin-top: 8px;
  font-size: 12px;
  font-weight: 600;
}

.question-instruction {
  margin-top: -3px;
  color: red;
  font-size: 12px;
  font-weight: 600;
}

.button-row {
  margin-top: 25px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.back-button-form {
  background-color: #37474F;
  
  color: #fff;
  margin-right: 400px;
  border: none;
  font-weight: 500;
  outline:none;
  width: 85px;
  height: 40px;
}

.forward-button-form {
  outline:none;
  font-weight: 500;
  border: none;
  background-color: #37474F;
  color: #fff;
  width: 85px;
  height: 40px;
  float: right;
}



.options-list {
  display: inline;
}

.options-labels {
  margin-left: 7px;
}



/* Form page collapsible */
.panel-group {
  margin-left: -15px;
  width: 104%;
  font-size: 21px;
}
.panel-default {
  width: 100%;
}
.panel-title {
  margin-top: 10px;
}

.panel-heading {
  width: 100%;
  height: 93px;
  color: white;
  background-color: #607D8B;
  border-radius: 5px;
  cursor: pointer;
  
}
.panel-title {
  line-height: 63px;  
}
.panel-texts {
  color: white;
  font-size: 17px;
  padding-left: 8px;
  line-height: 1;
  padding-top: 5px;
  height: 63px;
}

/* Form page popover */

.popover-buttons {
  background-color: white;
  color: black;
  box-shadow:none !important;
  border:none !important;
  line-height: initial;
}


button.popover-buttons {
  box-shadow: none;
  border: none;
  line-height: initial;
}
.popover-buttons:hover {
  box-shadow: none;
  border: none;
  line-height: initial;
}

.popover-buttons:active {
  background-color: red;
}

.emoji-smile {
  margin-right: 15px;
}
.emoji-neutral {
  margin-right: 15px;
}
.emoji-sad {
  margin-right: 15px;
}
.responsive-image {
  width: 727px;
  height: 482.72px;
}



a.button3{
  display:inline-block;
  padding:0.3em 1.2em;
  margin:0 0.3em 0.3em 0;
  border-radius:2em;
  box-sizing: border-box;
  text-decoration:none;
  font-family:'Roboto',sans-serif;
  font-weight:300;
  color:#FFFFFF;
  background-color:#4eb5f1;
  text-align:center;
  transition: all 0.2s;
  cursor: pointer;
  margin-left: 20%;
  }
  a.button3:hover{
  background-color:#4095c6;
  }
  @media all and (max-width:30em){
  a.button3{
  display:block;
  margin:0.2em auto;
  }
  }
  .button3:hover{
    background-color:#4095c6;
    }
  .button3{
    display:inline-block;
    padding:0.3em 1.2em;
    margin:0 0.3em 0.3em 0;
    border-radius:2em;
    box-sizing: border-box;
    text-decoration:none;
    font-family:'Roboto',sans-serif;
    font-weight:300;
    color:#FFFFFF;
    background-color:#4eb5f1;
    text-align:center;
    transition: all 0.2s;
    cursor: pointer;
    margin-left: 20%;
    }

  .input {
    margin: -8px
  }

  table {
    width: 100% !important;
  }

  input[type="radio"] {
    margin-left: 5px;
    width: 16px;
    height: 16px;
}

input[type=checkbox] {
  margin-left: 10px;
}

@media all and (max-width:800px){
  .options-section {
    font-size: 17px;
  }
  input[type=checkbox] {
  width: 19px;
  height: 19px;
}
  }
  input[type=checkbox] {
    height: 16px;
    width: 18px;
}

  .options-section {
  line-height: 4.1;
}

.sapr-table {
  line-height: 2 !important;
}

.mapr-table {
  line-height: 2 !important;
}

.table td, .table th {
  font-size: 18px;
}

input[type="number"] {
  width: 100%;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  margin-bottom: 20px;
  font-size: 22px;
}

.img-responsive {
  width: 100%;
  height: auto;
  max-width: 800px;
    max-height: 1000px;
}

